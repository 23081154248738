import React from 'react';
import HeroDesktopImage from '../../images/hero/background-images/redesign-imgs/new-hero-desktop.png';
import HeroTabletImage from '../../images/hero/background-images/redesign-imgs/hero-tablet.png';
import ZipformRedesign from '../core/zipform/ZipformRedesign';
import ExpansionTabRedesign from '../expansion-tab/ExpansionTabRedesign';
import Trust from './components/Trust';
import tabs from './components/TabData';
import useSharedTabState from '../../hooks/tabState';
import useWindowResize from '../../hooks/useWindowResize';
import constants from '../../globals/constants/application-constants';

const Hero = ({ defaultTab }: { defaultTab: number }) => {
  const QA_SECTION = 'Hero';
  const { active, setActive } = useSharedTabState();
  const windowInnerwidth = useWindowResize(true);
  const isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;

  return (
    <section
      className={`hero overflow-hidden ${tabs[active].name
        .split(' ')
        .join('-')
        .toLowerCase()} bg-gray-light text-gray-dark`}
    >
      <div className="relative flex flex-col-reverse xl:block max-w-screen-xl px-3 mx-auto pt-3 pb-3 md:px-6 md:pb-0 xl:px-4 xl:pt-4 xl:pb-0 xl:h-[625px]">
        {/* Image */}
        <div className="hidden xl:block xl:absolute xl:bg-no-repeat z-0 bottom-[0px] left-0">
          <img src={HeroDesktopImage} className="z-0" alt="" />
        </div>
        {/* Trustpilot */}
        <Trust qaSection={QA_SECTION} />
        <div className="relative z-10">
          <div className="overflow-hidden">
            <div className="relative mx-auto md:max-w-full lg:max-w-[804px] xl:max-w-[800px] xl:float-right">
              <div className="flex items-center xl:block">
                <img
                  src={HeroTabletImage}
                  className="hidden md:block xl:hidden w-[128px] h-[128px]"
                  alt=""
                />
                <h1 className="text-center border-b-[1px] border-gray-disabled md:border-b-0 px-1 pb-4 md:px-0 md:pb-0 font-bold text-20 text-green-950 leading-28 md:text-32 md:leading-[40px] xl:text-48 xl:leading-[64px]">
                  Compare the nation’s top<sup className="text-12 pl-[2px] md:text-16 md:pl-1 xl:text-24">1</sup> health insurance plans
                </h1>
              </div>
              <p className="font-semibold md:font-bold my-3 md:mt-0 lg:mt-3 text-black text-14 leading-20 md:text-16 md:leading-24 xl:text-20 xl:leading-28 mb-3 text-center">
                Select the coverage you need
              </p>
              <div className="hidden md:block">
                {/* Tabs: Desktop */}
                <div className="tabs max-w-[830px]">
                  <div className="tablist" role="tablist" aria-label="Insurance Types">
                    <span className="flex w-full">
                      {tabs.map((tab, index) => (
                        <button
                          type="button"
                          role="tab"
                          key={`desktopTab-${tab.name}`}
                          data-wa-link={`tab: ${tab.hero.type}`}
                          data-qa={`hero-${tab.hero.type}-tab`}
                          className={`md:w-[99px] md:h-[131px] font-poppin xl:w-[146px] xl:h-[130px] mb-[26px] flex-grow text-16 leading-18 py-[10px] shadow-md rounded-3xl hover:bg-[#D3E2D3] hover:border-[3px] hover:border-green-800 font-bold ${tab.hero.type !== 'st' ? 'mr-[20px]' : ''
                            } ${active === index
                              ? 'bg-white border-[3px] px-[9px] border-green-800 active-tab relative'
                              : 'bg-[#FCFDFD] text-gray-dark px-3 hover:px-[9px]'
                            }`}
                          onClick={() => setActive(index)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setActive(index);
                            }
                          }}
                        >
                          <img src={tab.icon} className="block mx-auto mb-2" alt="" />
                          <h2>{tab.name}</h2>
                        </button>
                      ))}
                    </span>
                  </div>
                </div>

                {/* Zipform Container */}
                <div
                  id="zip-form-container"
                  className="bg-white border-[3px] border-green-800 shadow-md xl:shadow-lg rounded-3xl max-w-[830px] mb-6 md:mb-3 xl:mb-6"
                >
                  <div className="p-4 md:pb-6 md:pt-6 md:px-8 xl:px-12 xl:py-6">
                    <ZipformRedesign qaSection={QA_SECTION} insuranceType={tabs[active].hero.type} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden xl:hidden flex flex-col">
            {tabs.map((tab, index) => {
              return (
                <div className={`open-${defaultTab}`} key={`mobileTab-${tab.name}`}>
                  <ExpansionTabRedesign position="hero" qaSection={QA_SECTION} tab={tab} index={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
